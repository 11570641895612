import {useEffect, useState} from "react";
import {isIPadDesktopMode, isMobile} from "@/Utils/index.js";
import {MarkupContext} from "@/Contexts/index.js";
import {PHONE_BREAKPOINT, SCALE_BREAKPOINT} from "../../config.static.js";
import {throttle} from "throttle-debounce";

export default function AppLayout({children}) {

    const [isMobileMode, setIsMobileMode] = useState(isMobile());
    const markupContextValue = {
        stateMobileMode: {
            isMobileMode,
            setIsMobileMode
        }
    };

    const mobileBreakpoint = PHONE_BREAKPOINT;
    const scaleBreakpoint = SCALE_BREAKPOINT;

    const resize = throttle(50, () => {
        let newScale;
        let vw = typeof document != "undefined" ? Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) : 0;
        if ( vw <= scaleBreakpoint && vw > mobileBreakpoint ) {
            newScale = vw / scaleBreakpoint;
        } else {
            newScale = 1;
        }
        if(typeof document != "undefined"){
            document.documentElement.style.setProperty("--scale", newScale.toString());
        }
        setIsMobileMode(isMobile());
    });

    useEffect(() => {
        if (typeof document != "undefined") {
            if (isIPadDesktopMode()) {
                document.body.classList.add('browser-ios-safari');
                document.documentElement.classList.add('browser-ios-safari');
            } else {
                document.body.classList.add('browser-others');
                document.documentElement.classList.add('browser-others');
            }

            window.addEventListener('resize', resize);
            window.addEventListener('orientationchange', resize);
            resize();
            return () => {
                window.removeEventListener('resize', resize);
                window.removeEventListener('orientationchange', resize);
            };
        }
    }, []);

    return (
        <>
            <MarkupContext.Provider value={markupContextValue}>
                <main className={'page'}>
                    {children}
                </main>
            </MarkupContext.Provider>
        </>
    );
}
