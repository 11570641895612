import {PHONE_BREAKPOINT} from "../../config.static.js";

export function getViewportWidth () {
	return typeof document != "undefined" ? Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) : 0;
}

export function isMobile () {
	const viewportWidth = getViewportWidth();
	return viewportWidth < PHONE_BREAKPOINT;
}

export function isDesktop () {
	return !isMobile();
}

export function getRandomInt (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function addLeadingZeros(num, targetLength) {
	let numStr = num.toString();
	while (numStr.length < targetLength) {
		numStr = '0' + numStr;
	}
	return numStr;
}

export function setLockScroll(isLock = true) {
    return isLock ? document.body.classList.add('scroll-lock') : document.body.classList.remove('scroll-lock');
}

// Dirty hack. Detect safari desktop mode on ipad
export function isIPadDesktopMode () {
    return /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}
